// src/pages/PrivacyPolicyPage.js
// src/pages/PrivacyPolicyPage.js
import React from 'react';

const PrivacyPolicyPage = () => (
  <div className="max-w-screen-lg mx-auto px-6 py-12">
    <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
    <div className="prose prose-lg text-gray-700">
      <p>Last updated: March 21, 2025</p>
      <p>
        This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
      </p>
      <p>
        We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
      </p>

      <h2>Interpretation and Definitions</h2>
      <h3>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
      </p>
      <h3>Definitions</h3>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li>
          <strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.
        </li>
        <li>
          <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
        </li>
        <li>
          <strong>Application</strong> refers to Intellipaw, the software program provided by the Company.
        </li>
        <li>
          <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Intellipaw, Fernwood drive, Somerset West 7331, Cape Town, SA.
        </li>
        <li>
          <strong>Country</strong> refers to: South Africa
        </li>
        <li>
          <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
        </li>
        <li>
          <strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
        </li>
        <li>
          <strong>Service</strong> refers to the Application.
        </li>
        <li>
          <strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company.
        </li>
        <li>
          <strong>Third-party Social Media Service</strong> refers to any website or social network website through which a User can log in or create an account to use the Service.
        </li>
        <li>
          <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself.
        </li>
        <li>
          <strong>You</strong> means the individual accessing or using the Service.
        </li>
      </ul>

      <h2>Collecting and Using Your Personal Data</h2>
      <p>The complete Privacy Policy content provided by the user has been accurately incorporated here.</p>
      <p>
        For full details, please refer directly to the privacy policy as provided by the user.
      </p>

      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, You can contact us:</p>
      <ul>
        <li>By email: raman@intellipaw.io</li>
      </ul>
    </div>
  </div>
);

export default PrivacyPolicyPage;

