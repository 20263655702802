// // src/pages/AboutUsPage.js
// import React from 'react';



// const DataForJustice = () => {
//   return (
//     <div className="bg-white text-navy">
//       {/* Page Container */}
//       <div className="max-w-screen-xl mx-auto px-4 md:px-8 py-12">

//         {/* Founder Section */}


//         {/* Data for Justice Project Section */}
//         <section className="mb-12">
//           <h2 className="text-2xl sm:text-3xl font-bold text-navy mb-4">Welcome to Data for Justice Project</h2>
//           <div className="p-6 bg-gray-50 rounded-lg shadow-md leading-relaxed space-y-4">
//             <p>
//               Dog fighting, a reprehensible underground crime, demands urgent legal and political
//               attention. Once condoned by aristocracy and embraced by medieval gentry, this blood
//               sport is now outlawed in S.A. Despite the ban, it persists in urban and rural areas,
//               alarming South Africa. Law enforcement, historically dismissing it as a mere animal
//               welfare concern, has failed to grasp its full extent. Communities scarred by dogfighting
//               pay the price for this indifference, as children grow up normalized to violence,
//               becoming desensitized and prone to criminalization. Dog fighters engage in a range of
//               criminal activities, necessitating a comprehensive approach to combat this pervasive
//               issue.
//             </p>
//             <p>
//               In recent years, enlightened authorities acknowledge the interconnected nature of
//               dogfighting with organized crime, drug distribution, and community violence. Denying its
//               prevalence is increasingly untenable in the face of compelling legal and empirical
//               evidence. It's time to confront this dark reality and take decisive action.
//             </p>
//             <a
//               href="https://animallaw.info"
//               className="text-blue-600 underline hover:text-blue-800"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               https://animallaw.info
//             </a>
//           </div>

//           {/* Take Action Section */}
//           <div className="mt-8 p-6 bg-gray-50 rounded-lg shadow-md space-y-4">
//             <h3 className="text-xl font-bold">Take Action!</h3>
//             <p>
//               Harnessing data is crucial in combating criminal activities, and the brutal practice of
//               dog fighting is no exception. Big data yields valuable insights into incident
//               geolocation, socioeconomic context, and victim animal breeds. Establishing a
//               comprehensive database can be a game-changer in addressing this tragedy.
//             </p>
//             <p>
//               Currently scarce due to illegality and criminal ties, data is closely guarded by the
//               SPCA's special investigations unit. Coba Tech aims to launch the "Data for Justice"
//               employing its PreCog Machine Learning API to analyze and deliver reliable probability
//               data on dog fighting hotspots. Combined with real-time app reports, this data will be
//               submitted securely to the NSPCA's SIU.
//             </p>
//             <a
//               href="#register"
//               className="inline-block bg-yellow-500 text-white py-2 px-4 rounded hover:bg-blue-800 transition-colors duration-200"
//             >
//               Register to participate
//             </a>
//           </div>
//         </section>

//       </div>
//     </div>
//   );
// };

// export default DataForJustice;




// src/pages/AboutUsPage.js
import React from 'react';
import ParticipantForm from '../components/ParticipantForm';  // Ensure path is correct

const DataForJustice = () => {
    return (
        <div className="bg-white text-navy">
            <div className="max-w-screen-xl mx-auto px-4 md:px-8 py-12">
                {/* Content here */}

                <section className="mb-12">
                    <h2 className="text-2xl sm:text-3xl font-bold text-navy mb-4">Welcome to Data for Justice Project</h2>
                    <div className="p-6 bg-gray-50 rounded-lg shadow-md leading-relaxed space-y-4">
                        <p>
                            Dog fighting, a reprehensible underground crime, demands urgent legal and political
                            attention. Once condoned by aristocracy and embraced by medieval gentry, this blood
                            sport is now outlawed in S.A. Despite the ban, it persists in urban and rural areas,
                            alarming South Africa. Law enforcement, historically dismissing it as a mere animal
                            welfare concern, has failed to grasp its full extent. Communities scarred by dogfighting
                            pay the price for this indifference, as children grow up normalized to violence,
                            becoming desensitized and prone to criminalization. Dog fighters engage in a range of
                            criminal activities, necessitating a comprehensive approach to combat this pervasive
                            issue.
                        </p>
                        <p>
                            In recent years, enlightened authorities acknowledge the interconnected nature of
                            dogfighting with organized crime, drug distribution, and community violence. Denying its
                            prevalence is increasingly untenable in the face of compelling legal and empirical
                            evidence. It's time to confront this dark reality and take decisive action.
                        </p>
                        <a
                            href="https://animallaw.info"
                            className="text-blue-600 underline hover:text-blue-800"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://animallaw.info
                        </a>
                    </div>

                    <div className="mt-8 p-6 bg-gray-50 rounded-lg shadow-md space-y-4">
                        <h3 className="text-xl font-bold">Take Action!</h3>
                        <p>
                            Harnessing data is crucial in combating criminal activities, and the brutal practice of
                            dog fighting is no exception. Big data yields valuable insights into incident
                            geolocation, socioeconomic context, and victim animal breeds. Establishing a
                            comprehensive database can be a game-changer in addressing this tragedy.
                        </p>
                        <p>
                            Currently scarce due to illegality and criminal ties, data is closely guarded by the
                            SPCA's special investigations unit. Coba Tech aims to launch the "Data for Justice"
                            employing its PreCog Machine Learning API to analyze and deliver reliable probability
                            data on dog fighting hotspots. Combined with real-time app reports, this data will be
                            submitted securely to the NSPCA's SIU.
                        </p>
                    </div>

                    {/* Registration Form Section */}
                    <div className="mt-8 bg-gray-50 p-6 rounded-lg shadow-md">
                        <h3 className="text-xl font-bold mb-4">Register as Participant</h3>
                        <ParticipantForm />
                    </div>
                </section>
            </div>
        </div>
    );
};

export default DataForJustice;


