// src/pages/ContactUsPage.js
import React, { useState } from 'react';
import appStoreIcon from '../assets/images/company/appstor.svg';
import playStoreIcon from '../assets/images/company/playstor.svg';

import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
} from 'react-icons/fa';

const ContactUsPage = () => {
  // State to manage form inputs
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can handle the form submission, e.g., send data to an API or email
    console.log('Form submitted:', formData);
    // Reset form
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <div className="max-w-screen-xl mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold mb-8 text-center">
        <span className="text-yellow-500">Contact</span> Us
      </h1>
      <div className="flex flex-col md:flex-row md:space-x-12">
        {/* Left Column - Contact Form */}
        <div className="md:w-1/2">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Name Input */}
            <div>
              <label htmlFor="name" className="block text-lg font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="mt-1 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            {/* Email Input */}
            <div>
              <label htmlFor="email" className="block text-lg font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="mt-1 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
                placeholder="you@example.com"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            {/* Message Input */}
            <div>
              <label htmlFor="message" className="block text-lg font-medium text-gray-700">
                Message
              </label>
              <textarea
                name="message"
                id="message"
                rows="6"
                className="mt-1 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
                placeholder="Your message..."
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            {/* Submit Button */}
            <div className="text-center">
              <button
                type="submit"
                className="px-6 py-3 bg-yellow-500 text-white font-medium text-lg rounded-full hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
        {/* Right Column - Contact Info */}
        <div className="md:w-1/2 mt-12 md:mt-0">
          <div className="space-y-6">
            {/* Location */}
            <div className="flex items-start">
              <FaMapMarkerAlt className="text-yellow-500 mt-1 w-6 h-6" />
              <div className="ml-4">
                <h3 className="text-lg font-medium">Our Location</h3>
                <p className="text-gray-600">
                Fernwood drive, Somerset West<br />
                7331, Cape Town, SA
                </p>
              </div>
            </div>
            {/* Phone Number */}
            <div className="flex items-start">
              <FaPhoneAlt className="text-yellow-500 mt-1 w-6 h-6" />
              <div className="ml-4">
                <h3 className="text-lg font-medium">Call Us</h3>
                <p className="text-gray-600">UAN: +27 71 682 8957</p>
              </div>
            </div>
            {/* Email */}
            <div className="flex items-start">
              <FaEnvelope className="text-yellow-500 mt-1 w-6 h-6" />
              <div className="ml-4">
                <h3 className="text-lg font-medium">Email Us</h3>
                <p className="text-gray-600">raman@intellipaw.io</p>
              </div>
            </div>
            {/* Social Media */}
            <div className="mt-6">
              <h3 className="text-lg font-medium mb-2">Follow Us</h3>
              <div className="flex space-x-4">
                <a href="https://web.facebook.com/cobatechIntelliPaw/" target="_blank" rel="noopener noreferrer">
                  <FaFacebookF className="text-gray-600 hover:text-yellow-500 w-8 h-8" />
                </a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                  <FaTwitter className="text-gray-600 hover:text-yellow-500 w-8 h-8" />
                </a>
                <a href=" https://www.instagram.com/intellipaw/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram className="text-gray-600 hover:text-yellow-500 w-8 h-8" />
                </a>
              </div>
            </div>
            {/* App Store and Play Store */}
            <div className="mt-6">
              <h3 className="text-lg font-medium mb-2">Get Our App</h3>
              <div className="flex space-x-4">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img src={appStoreIcon} alt="App Store" className="h-12 w-auto" />
                </a>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img src={playStoreIcon} alt="Play Store" className="h-12 w-auto" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
