// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
   
    apiKey: 'AIzaSyB9JiM5QPA2uB6rbe6ArOyNOJD9IPdBWzk',
    appId: '1:239143146010:web:9deaee80bf6e038c2e77a3',
    messagingSenderId: '239143146010',
    projectId: 'intellipaw',
    authDomain: 'intellipaw.firebaseapp.com',
    storageBucket: 'intellipaw.appspot.com',
    measurementId: 'G-K6MQPPFNQZ',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
