// src/components/JoinCommunity.js
import React from 'react';
// Import your image and Instagram icon
import communityImage from '../assets/dogimg/collage2.png'; // Update with your image path
import instagramIcon from '../assets/images/instagram1.png'; // Update with your Instagram icon path

const JoinCommunity = () => {
  return (
    <div className="mt-28">
      <div data-aos="flip-down" className="text-center mx-auto">
        {/* Section Heading */}
        <h1 className="text-2xl sm:text-3xl font-bold mb-4">
          Join our<span className="text-yellow-500"> active online</span> community
        </h1>

        {/* Description */}
        <p className="text-sm sm:text-base text-gray-700 mb-8 px-4 sm:px-0 max-w-2xl mx-auto">
          Connect with us on Instagram to be part of a vibrant community that shares tips,
          recipes, and personal stories about transforming health through smarter eating.
          Join us today and start your journey to better nutrition!
        </p>

        {/* Centered Image */}
        <div className="flex flex-col items-center px-4 sm:px-0">
          <img
            src={communityImage}
            alt="Community"
            className="mb-8 w-full max-h-[600px] object-contain"
          />

          {/* Instagram Button */}
          <a
            href="https://www.instagram.com/cobatechintellipaw/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-gradient-to-r from-pink-500 to-yellow-500 text-white px-6 py-3 rounded-full flex items-center hover:scale-105 transform transition duration-300">
              <img
                src={instagramIcon}
                alt="Instagram"
                className="w-6 h-6 mr-2"
              />
              Follow us on Instagram
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default JoinCommunity;
