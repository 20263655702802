// src/pages/AboutUsPage.js
import React from 'react';
import { FaLinkedin, FaInstagram, FaFacebook, FaTiktok } from 'react-icons/fa';
import FaqSection from '../components/FaqSection';

const AboutUsPage = () => {
  return (
    <div className="bg-white text-navy">
      {/* Page Container */}
      <div className="max-w-screen-xl mx-auto px-4 md:px-8 py-12">
        <h1 className="text-4xl font-bold text-center mb-10">About Us</h1>
        <section className="space-y-12">
          {/* Founder Section */}
          <div className="bg-cream p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-2">Raman Rajoo</h2>
            <h3 className="text-xl font-semibold mb-4">Founder</h3>
            <p className="text-base md:text-lg leading-relaxed">
              Raman's unwavering commitment revolves around elevating the quality of life for pets and their
              caretakers through the provision of a steadfast, trustworthy, and highly efficient platform designed
              to facilitate the prompt identification and reunification of lost pets with their respective families.
              His self-directed learning and steadfast determination are fueled by a deep-seated love for animals,
              complemented by a resolute entrepreneurial vision.
            </p>
          </div>

          {/* Team Members Section */}
          <div className="grid gap-8 md:grid-cols-2">
            {/* CFO Section */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-2">G Mosoahle</h2>
              <h3 className="text-xl font-semibold mb-4">CFO</h3>
              <p className="text-base md:text-lg leading-relaxed">
                Gugu holds a Masters in Development Finance. She is a strategically minded, forward-thinking
                Chartered Accountant and a Masters bearer with over 15 years of experience in financial analysis,
                risk management, and strategic planning in publicly traded companies within the financial services
                industry.
              </p>
            </div>

            {/* Development and CTO Section */}
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-2">Rizwan</h2>
              <h3 className="text-xl font-semibold mb-4">Development &amp; CTO</h3>
              <p className="text-base md:text-lg leading-relaxed">
                Rizwan is our head of development. He is a Data Scientist and Machine Learning Engineer with
                hands-on experience in various technologies including Scikit, TensorFlow, Keras, and OpenCV.
              </p>
              <p className="text-base md:text-lg leading-relaxed mt-4">
                Skills include, but are not limited to: Data Science, Python, Python Flask, MongoDB, Jupyter
                Notebook, Data Visualization, OpenCV, and Docker.
              </p>
            </div>
          </div>

          {/* Investment Opportunity Section */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl sm:text-3xl font-bold text-navy mb-4">Investment Opportunity</h2>
            <div className="space-y-4 leading-relaxed">
              <p>
                Discover the potential of IntelliPaw, the innovative AI-driven solution at the forefront of pet
                safety. Our unique technology harnesses the power of machine learning to accurately identify and
                protect our cherished companions with just a scan of their snout print.
              </p>
              <p>
                As we chart a course for growth and industry leadership, we invite forward-thinking investors to
                explore the remarkable opportunities IntelliPaw offers. Visit our website to see the impactful
                difference your investment can make in the booming pet care market and be part of a visionary journey
                to redefine pet protection.
              </p>
              <a
                href="#pitch"
                className="inline-block bg-yellow-500 text-white py-2 px-4 rounded hover:bg-green-700 transition-colors duration-200"
              >
                View the Investment Pitch
              </a>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <div className="mt-12">
          <FaqSection />
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
