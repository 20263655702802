// src/components/FaqSection.js
import React, { useState } from 'react';
import FaqItem from './FaqItem';

const faqs = [
  {
    question: "What is IntelliPaw, and how does it work?",
    answer: (
      <p>
        IntelliPaw is an application that identifies a dog or cat by its unique nose print.
        Simply upload at least 10 pics of a dog's snout and IntelliPaw does the rest.
        This is achieved through a combination of machine learning and convolutional neural networks.
        IntelliPaw will process the pics, extract the biometric data and run it against our
        database of registered pets and owners. Upon identification, the owner is notified and
        put into contact with the rescuer.
      </p>
    ),
  },
  {
    question: "Where can I download the IntelliPaw app?",
    answer: (
      <p>
        Simply search "IntelliPaw" on Google Play Store & App Store
      </p>
    ),
  },
  {
    question: "How can I invest in your startup?",
    answer: (
      <p>
        Fill out a contact form and Raman will make contact to answer any of your questions.
      </p>
    ),
  },
  {
    question: "Is my data safe?",
    answer: (
      <p>
        Yes, we take data privacy seriously. Your pet's data is stored securely, and we have
        measures in place to protect it from unauthorized access. You have full control over
        who can access your pet's information.
      </p>
    ),
  },
  {
    question: "Can I use IntelliPaw for other animals?",
    answer: (
      <p>
        IntelliPaw is primarily designed for cats and dogs.
      </p>
    ),
  },
  {
    question: "Do you offer any discounts?",
    answer: (
      <div>
        <p>
          Yes we do. Discounts are offered to individuals that can provide proof of unemployment
          or government grant. Email this info to{' '}
          <a href="mailto:raman@coba-tech.com" className="text-blue-600 underline">
            raman@coba-tech.com
          </a>.
        </p>
        <p>
          For Animal rescue organisations, email proof of your CIPC document to{' '}
          <a href="mailto:raman@coba-tech.com" className="text-blue-600 underline">
            raman@coba-tech.com
          </a>.
        </p>
      </div>
    ),
  },
  {
    question: "Do you have a social media account?",
    answer: (
      <div>
        <p>Yes, we do. Connect with us on:</p>
        <ul className="list-none mt-2 space-y-2">
          <li><a href="https://www.linkedin.com/in/coba-tech-intellipaw" className="text-blue-600 underline">LinkedIn</a></li>
          <li><a href="https://www.instagram.com/cobatechintellipaw/" className="text-pink-600 underline">Instagram</a></li>
          <li><a href="https://web.facebook.com/cobatechIntelliPaw/" className="text-blue-700 underline">Facebook</a></li>
          <li><a href="https://www.tiktok.com/@cobatech_intellipaw" className="text-black underline">TikTok</a></li>
        </ul>
      </div>
    ),
  }
];

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className="my-12">
      <h2 className="text-2xl sm:text-3xl font-bold text-navy mb-4">FAQ</h2>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <FaqItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={activeIndex === index}
            onClick={() => handleToggle(index)}
          />
        ))}
      </div>
    </section>
  );
};

export default FaqSection;
