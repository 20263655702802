// import React from 'react';

// const ParticipantForm = () => {
//   return (
//     <form className="space-y-6 bg-white p-5 rounded-lg shadow-lg">
//       <div className="space-y-4">
//         <h2 className="text-xl font-semibold text-gray-800">Personal Information</h2>
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//           <div>
//             <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
//             <input type="text" id="name" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" defaultValue="Safi Rehman" />
//           </div>
//           <div>
//             <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
//             <input type="email" id="email" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" defaultValue="emailsafirehman@gmail.com" />
//           </div>
//           <div>
//             <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Telephone #</label>
//             <input type="tel" id="phone" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
//           </div>
//           <div>
//             <label htmlFor="altPhone" className="block text-sm font-medium text-gray-700">Alt Telephone #</label>
//             <input type="tel" id="altPhone" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
//           </div>
//           <div>
//             <label htmlFor="orgName" className="block text-sm font-medium text-gray-700">Organization Name</label>
//             <input type="text" id="orgName" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
//           </div>
//           <div>
//             <label htmlFor="regNumber" className="block text-sm font-medium text-gray-700">Reg/NPO number</label>
//             <input type="text" id="regNumber" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
//           </div>
//         </div>
//       </div>
      
//       <div className="space-y-4">
//         <h2 className="text-xl font-semibold text-gray-800">Incident Details</h2>
//         <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
//           <div>
//             <label htmlFor="streetName" className="block text-sm font-medium text-gray-700">Street Name</label>
//             <input type="text" id="streetName" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
//           </div>
//           <div>
//             <label htmlFor="suburb" className="block text-sm font-medium text-gray-700">Suburb</label>
//             <input type="text" id="suburb" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
//           </div>
//           <div>
//             <label htmlFor="province" className="block text-sm font-medium text-gray-700">Province</label>
//             <input type="text" id="province" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
//           </div>
//           <div>
//             <label htmlFor="incidentDate" className="block text-sm font-medium text-gray-700">Date of Incident</label>
//             <input type="date" id="incidentDate" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
//           </div>
//           <div>
//             <label htmlFor="incidentTime" className="block text-sm font-medium text-gray-700">Time of Incident</label>
//             <input type="time" id="incidentTime" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
//           </div>
//           <div>
//             <label htmlFor="caseNumber" className="block text-sm font-medium text-gray-700">SAPS CASE Nr (If available)</label>
//             <input type="text" id="caseNumber" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
//           </div>
//           <div className="col-span-3">
//             <label htmlFor="incidentDescription" className="block text-sm font-medium text-gray-700">Detailed Description of Incident</label>
//             <textarea id="incidentDescription" className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" rows="5" placeholder="Provide a detailed description of the incident..."></textarea>
//           </div>
//           <div className="col-span-3">
//             <label htmlFor="file" className="block text-sm font-medium text-gray-700">Upload File (Max 15MB)</label>
//             <input type="file" id="file" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
//           </div>
//         </div>
//       </div>

//       <button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-300">Submit</button>
//     </form>
//   );
// };

// export default ParticipantForm;


import React, { useState } from 'react';
import { db } from '../firebase'; // Adjust path as necessary
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const ParticipantForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    altPhone: '',
    orgName: '',
    regNumber: '',
    streetName: '',
    suburb: '',
    province: '',
    incidentDate: '',
    incidentTime: '',
    caseNumber: '',
    incidentDescription: '',
    file: null
  });
  const [submitting, setSubmitting] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      setFormData({ ...formData, file: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setErrorMsg('');
    setSuccessMsg('');

    try {
      let fileUrl = null;

      if (formData.file) {
        const storage = getStorage();
        const storageRef = ref(storage, `DataForJustice/${Date.now()}_${formData.file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, formData.file);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              // Optional: Implement progress monitoring here
            },
            (error) => {
              console.error("File upload failed:", error);
              setErrorMsg('File upload failed. Please try again.');
              setSubmitting(false);
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref)
                .then((downloadURL) => {
                  fileUrl = downloadURL;
                  resolve();
                })
                .catch((error) => {
                  console.error("Error getting download URL:", error);
                  setErrorMsg('Error retrieving file URL. Please try again.');
                  setSubmitting(false);
                  reject(error);
                });
            }
          );
        });
      }

      // Combine incidentDate and incidentTime into a single timestamp
      let incidentTimestamp = null;
      if (formData.incidentDate && formData.incidentTime) {
        incidentTimestamp = new Date(`${formData.incidentDate}T${formData.incidentTime}`);
      } else if (formData.incidentDate) {
        incidentTimestamp = new Date(formData.incidentDate);
      } else if (formData.incidentTime) {
        // If only time is provided, set today's date with the provided time
        const today = new Date();
        const [hours, minutes] = formData.incidentTime.split(':');
        today.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
        incidentTimestamp = today;
      }

      const dataToSave = { 
        ...formData, 
        fileUrl: fileUrl, 
        incidentTimestamp: incidentTimestamp,
        createdAt: serverTimestamp() 
      };
      delete dataToSave.file; // Remove file from data to save
      delete dataToSave.incidentDate; // Remove separate incidentDate
      delete dataToSave.incidentTime; // Remove separate incidentTime

      await addDoc(collection(db, "DataForJustice"), dataToSave);
      setSuccessMsg('Form submitted successfully!');
      setFormData({
        name: '',
        email: '',
        phone: '',
        altPhone: '',
        orgName: '',
        regNumber: '',
        streetName: '',
        suburb: '',
        province: '',
        incidentDate: '',
        incidentTime: '',
        caseNumber: '',
        incidentDescription: '',
        file: null
      });
    } catch (error) {
      console.error("Error submitting form: ", error);
      if (!errorMsg) { // Prevent overwriting if already set
        setErrorMsg('Error submitting form. Please try again.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form 
      className="space-y-6 bg-white p-5 rounded-lg shadow-lg" 
      onSubmit={handleSubmit} 
      noValidate
    >
      {successMsg && (
        <div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg" role="alert">
          {successMsg}
        </div>
      )}
      {errorMsg && (
        <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
          {errorMsg}
        </div>
      )}
      
      <div className="space-y-4">
        <h2 className="text-xl font-semibold text-gray-800">Personal Information</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name<span className="text-red-500">*</span></label>
            <input 
              type="text" 
              id="name" 
              name="name"
              value={formData.name}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              onChange={handleChange} 
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email<span className="text-red-500">*</span></label>
            <input 
              type="email" 
              id="email" 
              name="email"
              value={formData.email}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              onChange={handleChange} 
            />
          </div>
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Telephone #<span className="text-red-500">*</span></label>
            <input 
              type="tel" 
              id="phone" 
              name="phone"
              value={formData.phone}
              required
              pattern="[0-9]{10,15}" 
              title="Please enter a valid phone number."
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              onChange={handleChange} 
            />
          </div>
          <div>
            <label htmlFor="altPhone" className="block text-sm font-medium text-gray-700">Alt Telephone #</label>
            <input 
              type="tel" 
              id="altPhone" 
              name="altPhone"
              value={formData.altPhone}
              pattern="[0-9]{10,15}" 
              title="Please enter a valid phone number."
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              onChange={handleChange} 
            />
          </div>
          <div>
            <label htmlFor="orgName" className="block text-sm font-medium text-gray-700">Organization Name</label>
            <input 
              type="text" 
              id="orgName" 
              name="orgName"
              value={formData.orgName}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              onChange={handleChange} 
            />
          </div>
          <div>
            <label htmlFor="regNumber" className="block text-sm font-medium text-gray-700">Reg/NPO Number</label>
            <input 
              type="text" 
              id="regNumber" 
              name="regNumber"
              value={formData.regNumber}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              onChange={handleChange} 
            />
          </div>
        </div>
      </div>
      
      <div className="space-y-4">
        <h2 className="text-xl font-semibold text-gray-800">Incident Details</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label htmlFor="streetName" className="block text-sm font-medium text-gray-700">Street Name</label>
            <input 
              type="text" 
              id="streetName" 
              name="streetName"
              value={formData.streetName}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              onChange={handleChange} 
            />
          </div>
          <div>
            <label htmlFor="suburb" className="block text-sm font-medium text-gray-700">Suburb</label>
            <input 
              type="text" 
              id="suburb" 
              name="suburb"
              value={formData.suburb}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              onChange={handleChange} 
            />
          </div>
          <div>
            <label htmlFor="province" className="block text-sm font-medium text-gray-700">Province</label>
            <input 
              type="text" 
              id="province" 
              name="province"
              value={formData.province}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              onChange={handleChange} 
            />
          </div>
          <div>
            <label htmlFor="incidentDate" className="block text-sm font-medium text-gray-700">Date of Incident</label>
            <input 
              type="date" 
              id="incidentDate" 
              name="incidentDate"
              value={formData.incidentDate}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              onChange={handleChange} 
            />
          </div>
          <div>
            <label htmlFor="incidentTime" className="block text-sm font-medium text-gray-700">Time of Incident</label>
            <input 
              type="time" 
              id="incidentTime" 
              name="incidentTime"
              value={formData.incidentTime}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              onChange={handleChange} 
            />
          </div>
          <div>
            <label htmlFor="caseNumber" className="block text-sm font-medium text-gray-700">SAPS CASE Nr (If available)</label>
            <input 
              type="text" 
              id="caseNumber" 
              name="caseNumber"
              value={formData.caseNumber}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              onChange={handleChange} 
            />
          </div>
          <div className="col-span-3">
            <label htmlFor="incidentDescription" className="block text-sm font-medium text-gray-700">Detailed Description of Incident</label>
            <textarea 
              id="incidentDescription" 
              name="incidentDescription"
              value={formData.incidentDescription}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              rows="5" 
              placeholder="Provide a detailed description of the incident..." 
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="col-span-3">
            <label htmlFor="file" className="block text-sm font-medium text-gray-700">Upload File (Max 15MB)</label>
            <input 
              type="file" 
              id="file" 
              name="file"
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              onChange={handleChange} 
            />
          </div>
        </div>
      </div>

      <button 
        type="submit" 
        disabled={submitting}
        className={`w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-300 ${submitting ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {submitting ? 'Submitting...' : 'Submit'}
      </button>
    </form>
  );
};

export default ParticipantForm;
