// // src/components/Membership.js
// import React from 'react';

// const membershipPlans = [
//   {
//     plan: 'Premium',
//     duration: 'Lifetime',
//     amount: 'R1800',
//     type: 'once off',
//     features: [
//       'Unlimited Access',
//       'Priority Support',
//       'Exclusive Content',
//       'Free Updates',
//     ],
//   },
//   {
//     plan: 'Basic',
//     duration: 'Lifetime',
//     amount: 'R1200',
//     type: 'once off',
//     features: [
//       'Limited Access',
//       'Standard Support',
//       'Basic Content',
//     ],
//   },
//   {
//     plan: 'Premium',
//     duration: 'Monthly',
//     amount: 'R69',
//     type: 'monthly',
//     features: [
//       'Unlimited Access',
//       'Priority Support',
//       'Exclusive Content',
//       'Free Updates',
//     ],
//   },
//   {
//     plan: 'Basic',
//     duration: 'Monthly',
//     amount: 'R49',
//     type: 'monthly',
//     features: [
//       'Limited Access',
//       'Standard Support',
//       'Basic Content',
//     ],
//   },
// ];

// const Membership = () => {
//   return (
//     <section className="py-16 bg-gray-100">
//     <div className="flex flex-col items-center w-full mt-8 mb-16 px-4 md:px-16 mx-auto max-w-screen-2xl ">
//       <h1 className="text-2xl sm:text-3xl  font-bold text-center mb-4">
//         <span className="text-yellow-500">Membership</span> Plans
//       </h1>
//       <div className="w-full">
//         <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
//           {membershipPlans.map((plan, index) => (
//             <div
//               key={index}
//               className={`bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 ${
//                 plan.plan === 'Premium'
//               }`}
//             >
//               <div className="p-6">
//                 <h3 className="text-2xl font-semibold mb-2">{plan.plan}</h3>
//                 <p className="text-gray-600 mb-1">
//                   <span className="font-medium">Duration:</span> {plan.duration}
//                 </p>
//                 <p className="text-yellow-500 text-xl font-bold mb-4">
//                   {plan.amount} <span className="text-gray-600">{plan.type}</span>
//                 </p>
//                 <ul className="text-gray-600 mb-4">
//                   {plan.features.map((feature, idx) => (
//                     <li key={idx} className="flex items-center mb-2">
//                       <svg
//                         className="w-5 h-5 text-yellow-500 mr-2 flex-shrink-0"
//                         fill="currentColor"
//                         viewBox="0 0 20 20"
//                       >
//                         <path
//                           fillRule="evenodd"
//                           d="M16.707 5.293a1 1 0 010 1.414L8.414 15 3.707 10.293a1 1 0 00-1.414 1.414l5 5a1 1 0 001.414 0l9-9a1 1 0 00-1.414-1.414L8.414 13.586 16.707 5.293a1 1 0 011.414 0z"
//                           clipRule="evenodd"
//                         />
//                       </svg>
//                       {feature}
//                     </li>
//                   ))}
//                 </ul>
//                 {/* <button className="w-full bg-yellow-500 text-white py-2 px-4 rounded-md hover:bg-yellow-600 transition-colors duration-300">
//                   Select Plan
//                 </button> */}
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//     </section>
//   );
// };

// export default Membership;

















// File: src/components/Membership.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const membershipData = [
  {
    customerType: 'Individual Pet Owner',
    heading: 'For individual use / pet owners',
    subscriptions: [
      {
        type: 'Basic',
        freemium: 'Basic Subscription 14 days',
        amount: 'R49,00',
        discount: '8%',
        discountCharge: 'R3,92',
        cancellation: 'R45,08 Anytime',
      },
      {
        type: 'Premium',
        freemium: 'Not applicable',
        amount: 'R69,00',
        discount: '10%',
        discountCharge: 'R6,90',
        cancellation: 'R62,10 Anytime',
      },
      {
        type: 'Basic Life Time',
        freemium: 'Not applicable',
        amount: 'R420,00',
        discount: '8%',
        discountCharge: 'R33,60',
        cancellation: 'R386,40 Anytime',
      },
      {
        type: 'Premium Life Time',
        freemium: 'Not applicable',
        amount: 'R660,00',
        discount: '13%',
        discountCharge: 'R82,50',
        cancellation: 'R577,50 Anytime',
      },
    ],
  },
  {
    customerType: 'Business (Retailers, Vets, Insurers)',
    heading: 'For business (retailers, vets, insurers)',
    subscriptions: [
      {
        type: 'Basic',
        freemium: 'Basic Subscription 14 days',
        amount: 'R49,00',
        discount: '10%',
        discountCharge: 'R4,90',
        cancellation: 'R44,10 Anytime',
      },
      {
        type: 'Premium',
        freemium: 'Not applicable',
        amount: 'R69,00',
        discount: '12%',
        discountCharge: 'R8,28',
        cancellation: 'R60,72 Anytime',
      },
      {
        type: 'Basic Life Time',
        freemium: 'Not applicable',
        amount: 'R420,00',
        discount: '5%',
        discountCharge: 'R21,00',
        cancellation: 'R399,00 Anytime',
      },
      {
        type: 'Premium Life Time',
        freemium: 'Not applicable',
        amount: 'R660,00',
        discount: '6%',
        discountCharge: 'R39,60',
        cancellation: 'R620,40 Anytime',
      },
    ],
  },
];

const Membership = () => {
  // 1) Read user from localStorage
  const storedUser = localStorage.getItem('myAppUser');
  const user = storedUser ? JSON.parse(storedUser) : null;

  // 2) Tabs logic (existing)
  const [activeTab, setActiveTab] = useState('individual');
  const individualData = membershipData.find((d) =>
    d.customerType.includes('Individual')
  );
  const businessData = membershipData.find((d) =>
    d.customerType.includes('Business')
  );
  const displayedData = activeTab === 'individual' ? individualData : businessData;

  return (
    <section className="py-16 bg-gray-100 relative">
      {/* 
        We wrap the ENTIRE membership UI in a container 
        that can be blurred if user is NOT logged in
      */}
      <div className={user ? '' : 'filter blur-sm pointer-events-none'}>
        <div className="flex flex-col items-center w-full mt-8 mb-16 px-4 md:px-16 mx-auto max-w-screen-2xl">

          {/* Main Title */}
          <h1 className="text-2xl sm:text-3xl font-bold text-center mb-4">
            <span className="text-yellow-500">Membership</span> Plans
          </h1>

          {/* Modern Toggle (Individual / Business) */}
          <div className="flex items-center justify-center mb-8">
            <div className="bg-gray-200 rounded-full p-1 flex w-72">
              {/* Individual Button */}
              <button
                onClick={() => setActiveTab('individual')}
                className={`flex-1 text-center py-2 rounded-full font-medium transition-colors
                  ${
                    activeTab === 'individual'
                      ? 'bg-yellow-500 text-white'
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
              >
                Individual
              </button>
              {/* Business Button */}
              <button
                onClick={() => setActiveTab('business')}
                className={`flex-1 text-center py-2 rounded-full font-medium transition-colors
                  ${
                    activeTab === 'business'
                      ? 'bg-yellow-500 text-white'
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
              >
                Business
              </button>
            </div>
          </div>

          {/* Subheading */}
          {displayedData && (
            <h2 className="text-xl sm:text-2xl font-semibold text-center mb-8 text-gray-800">
              {displayedData.heading}
            </h2>
          )}

          {/* Cards Grid */}
          {displayedData && (
            <div className="w-full">
              <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
                {displayedData.subscriptions.map((sub, index) => (
                  <div
                    key={index}
                    className="bg-white rounded-lg shadow-lg overflow-hidden
                               hover:shadow-2xl transition-shadow duration-300"
                  >
                    <div className="p-6">
                      {/* Subscription Type */}
                      <h3 className="text-2xl font-semibold mb-2">{sub.type}</h3>

                      {/* Freemium Info */}
                      <p className="text-gray-600 mb-1">
                        <span className="font-medium">Freemium:</span> {sub.freemium}
                      </p>

                      {/* Amount */}
                      <p className="text-yellow-500 text-xl font-bold mb-4">
                        {sub.amount}
                      </p>

                      {/* Key Points as a List */}
                      <ul className="text-gray-600 mb-4 space-y-2">
                        <li className="flex items-center">
                          <svg
                            className="w-5 h-5 text-yellow-500 mr-2 flex-shrink-0"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414L8.414 15 
                                 3.707 10.293a1 1 0 00-1.414 1.414l5 5a1 1 0 
                                 001.414 0l9-9a1 1 0 
                                 00-1.414-1.414L8.414 13.586 
                                 16.707 5.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <strong>Discount:</strong> {sub.discount}
                        </li>
                        <li className="flex items-center">
                          <svg
                            className="w-5 h-5 text-yellow-500 mr-2 flex-shrink-0"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414L8.414 15 
                                 3.707 10.293a1 1 0 00-1.414 1.414l5 5a1 1 0 
                                 001.414 0l9-9a1 1 0 
                                 00-1.414-1.414L8.414 13.586 
                                 16.707 5.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <strong>Discount Charge:</strong> {sub.discountCharge}
                        </li>
                        <li className="flex items-center">
                          <svg
                            className="w-5 h-5 text-yellow-500 mr-2 flex-shrink-0"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414L8.414 15 
                                 3.707 10.293a1 1 0 00-1.414 1.414l5 5a1 1 0 
                                 001.414 0l9-9a1 1 0 
                                 00-1.414-1.414L8.414 13.586 
                                 16.707 5.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <strong>Cancellation:</strong> {sub.cancellation}
                        </li>
                      </ul>

                      {/* (Optional) Button */}
                      {/* <button className="w-full bg-yellow-500 text-white py-2 px-4 rounded-md 
                                    hover:bg-yellow-600 transition-colors duration-300">
                        Select Plan
                      </button> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* 
        If there's NO user, show an overlay 
        that prompts them to register to unlock.
      */}
      {!user && (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-40 text-white">
          <h3 className="text-xl md:text-2xl font-semibold mb-2">Membership Locked</h3>
          <p className="mb-4 text-center max-w-xs px-2">
            Please register or log in to unlock and access these membership plans.
          </p>
          <Link
            to="/register"
            className="bg-indigo-600 hover:bg-indigo-700 px-4 py-2 rounded font-medium"
          >
            Register Now
          </Link>
        </div>
      )}
    </section>
  );
};

export default Membership;


