// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import ArticleDetail from './components/ArticleDetail';
import BlogPage from './pages/BlogPage';
import ContactUsPage from './pages/ContactUsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import MissingPetsPage from './pages/MissingPetsPage';
import Aboutus from './pages/AboutUsPage';
import DataForJustice from './pages/DataForJustice';

// 1. Import your new Register component
import Register from './components/Register';

const App = () => {
  // 2. Check if a user is in localStorage
  const storedUser = localStorage.getItem('myAppUser');
  const user = storedUser ? JSON.parse(storedUser) : null;

  return (
    <Router>
      <Navbar />
      <Routes>

        {/* ---------------------------------------------- */}
        {/* (Option A) If you want HomePage always accessible: */}
        <Route path="/" element={<HomePage />} />

        {/* (Option B) If you want HomePage locked behind registration, 
            uncomment the line below and comment out the line above */}
        {/* 
        <Route
          path="/"
          element={
            user ? <HomePage /> : <Navigate to="/register" />
          }
        /> 
        */}

        {/* 3. Your existing routes: */}
        <Route path="/articles/:slug" element={<ArticleDetail />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/About-us" element={<Aboutus />} />
        <Route path="/Data-for-justice" element={<DataForJustice />} />
        <Route path="/missing-pet" element={<MissingPetsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-of-service" element={<TermsOfServicePage />} />

        {/* 4. Add a route for /register */}
        <Route path="/register" element={<Register />} />

        {/* Add any other routes you might need */}
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
