// // src/components/MissingPets.js

// import React, { useEffect, useState } from 'react';
// import { db } from '../firebase'; // Adjust path if necessary
// import { collectionGroup, query, where, getDocs } from 'firebase/firestore';

// const MissingPets = () => {
//   const [missingPets, setMissingPets] = useState([]);
//   const [selectedPet, setSelectedPet] = useState(null);

//   useEffect(() => {
//     const fetchAllMissingPets = async () => {
//       try {
//         const petsQuery = query(
//           collectionGroup(db, 'Pets'),
//           where('petCurrentStatus.petStatus', '==', 'Missing')
//         );
//         const querySnapshot = await getDocs(petsQuery);

//         const allMissingPets = querySnapshot.docs.map(doc => {
//           return { id: doc.id, ...doc.data() };
//         });

//         setMissingPets(allMissingPets);
//       } catch (error) {
//         console.error('Error fetching all missing pets:', error);
//       }
//     };

//     fetchAllMissingPets();
//   }, []);

//   // Optional: Map genders to symbols or icons
//   const genderSymbol = (gender) => {
//     if (!gender) return '';
//     const lower = gender.toLowerCase();
//     if (lower === 'male') return '♂';
//     if (lower === 'female') return '♀';
//     return ''; // No symbol if unknown
//   };

//   const openModal = (pet) => {
//     setSelectedPet(pet);
//   };

//   const closeModal = () => {
//     setSelectedPet(null);
//   };

//   // Extract finder details from ListOfVet array
//   const getFinderDetails = (pet) => {
//     const vetList = pet?.petCurrentStatus?.ListOfVet || [];
//     const finder = vetList[0] || {};
//     return {
//       name: finder.name || 'N/A',
//       phone: finder.phone || 'N/A',
//       address: finder.address || 'N/A'
//     };
//   };

//   return (
//     <div className="flex flex-col items-center w-full mt-8 mb-16 px-4 md:px-16 mx-auto max-w-screen-2xl">
//       <h2 className="text-3xl font-bold text-center mb-6">
//      Lost & Found <span className="text-yellow-500">Corner</span> 
//       </h2>
//       <div className="flex flex-col justify-center items-center w-full">
//         <div data-aos="zoom-in-right" className="w-full">
//           <div className="flex justify-center">
//             <div className="overflow-x-auto w-full">
//               <div className="flex space-x-4 px-2">
//                 {missingPets.map((pet) => {
//                   const symbol = genderSymbol(pet.petGender);
//                   return (
//                     <div
//                       key={pet.id}
//                       onClick={() => openModal(pet)}
//                       className="flex-shrink-0 bg-white rounded-lg shadow-lg m-2 p-4 space-y-4 w-64 cursor-pointer hover:shadow-xl transition-shadow duration-200"
//                     >
//                       {/* Image Container */}
//                       <div className="w-full aspect-square overflow-hidden rounded-xl">
//                         <img
//                           src={pet.petImage}
//                           alt={`${pet.name} Image`}
//                           className="w-full h-full object-cover"
//                         />
//                       </div>
//                       {/* Pet Details */}
//                       <h3 className="text-lg font-semibold text-center">{pet.name}</h3>
//                       <p className="text-gray-500 text-center">
//                         {pet.petAge} Year - {pet.petGender} {symbol}
//                       </p>
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Modal */}
//       {selectedPet && (
//         <div className="fixed inset-0 flex items-center justify-center z-50">
//           {/* Overlay */}
//           <div className="absolute inset-0 bg-black bg-opacity-50" onClick={closeModal}></div>
//           {/* Modal Container */}
//           <div className="relative bg-white rounded-xl shadow-2xl p-6 md:p-8 w-11/12 max-w-4xl mx-auto z-10">
//             {/* Close Button */}
//             <button
//               onClick={closeModal}
//               className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
//             >
//               <svg
//                 className="h-6 w-6"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>

//             {/* Modal Content */}
//             <div className="flex flex-col md:flex-row items-start md:space-x-6">
//               {/* Image Section */}
//               <div className="flex-shrink-0 w-full md:w-1/2 flex justify-center mb-2 md:mb-0">
//                 <div className="w-64 h-64 bg-gray-100 rounded-xl overflow-hidden">
//                   <img
//                     src={selectedPet.petImage}
//                     alt={selectedPet.name}
//                     className="w-full h-full object-cover"
//                   />
//                 </div>
//               </div>
              
//               {/* Details Section */}
//               <div className="flex-grow">
//                 <h3 className="text-2xl font-bold mb-2"> <span className="text-yellow-500">{selectedPet.name}</span> </h3>
//                 <p className="text-gray-600 mb-4">
//                   Age: {selectedPet.petAge} Year(s) | Gender: {selectedPet.petGender} {genderSymbol(selectedPet.petGender)}
//                 </p>
                
//                 <div className="space-y-2">
//                   <h4 className="text-xl font-semibold text-gray-800">Owner Details</h4>
//                   <p className="text-gray-700">Name: {selectedPet.petOwner?.ownerFullName || 'N/A'}</p>
//                   <p className="text-gray-700">Phone: {selectedPet.petOwner?.ownerPrimaryPhone || 'N/A'}</p>
//                 </div>
                
//                 <div className="mt-4 space-y-2">
//                   <h4 className="text-xl font-semibold text-gray-800">Finder/Vet Details</h4>
//                   {(() => {
//                     const { name, phone, address } = getFinderDetails(selectedPet);
//                     return (
//                       <>
//                         <p className="text-gray-700">Name: {name}</p>
//                         <p className="text-gray-700">Phone: {phone}</p>
//                         <p className="text-gray-700">Address: {address}</p>
//                       </>
//                     );
//                   })()}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default MissingPets;






import React, { useEffect, useState } from 'react';
import { db } from '../firebase'; // Adjust path if necessary
import { collectionGroup, query, where, getDocs } from 'firebase/firestore';

const MissingPets = () => {
  const [missingPets, setMissingPets] = useState([]);
  const [selectedPet, setSelectedPet] = useState(null);
  const [petAddress, setPetAddress] = useState('');

  useEffect(() => {
    const fetchAllMissingPets = async () => {
      try {
        const petsQuery = query(
          collectionGroup(db, 'Pets'),
          where('petCurrentStatus.petStatus', '==', 'Missing')
        );
        const querySnapshot = await getDocs(petsQuery);

        const allMissingPets = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setMissingPets(allMissingPets);
      } catch (error) {
        console.error('Error fetching all missing pets:', error);
      }
    };

    fetchAllMissingPets();
  }, []);

  const openModal = (pet) => {
    setSelectedPet(pet);
  };

  const closeModal = () => {
    setSelectedPet(null);
    setPetAddress('');
  };

  // Function to reverse geocode lat & lng to an address
 // Function to reverse geocode lat & lng to an address using Nominatim
const fetchAddress = async (lat, lng) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
    );
    const data = await response.json();
    if (data && data.display_name) {
      return data.display_name;
    } else {
      return 'Address not found';
    }
  } catch (error) {
    console.error('Error fetching address:', error);
    return 'Error fetching address';
  }
};


  // When a pet is selected, fetch the address based on its latitude and longitude.
  useEffect(() => {
    const getAddress = async () => {
      if (
        selectedPet &&
        selectedPet.petCurrentStatus &&
        selectedPet.petCurrentStatus.missingPetLatitude &&
        selectedPet.petCurrentStatus.missingPetLongitude
      ) {
        const { missingPetLatitude, missingPetLongitude } = selectedPet.petCurrentStatus;
        const address = await fetchAddress(missingPetLatitude, missingPetLongitude);
        setPetAddress(address);
      }
    };

    getAddress();
  }, [selectedPet]);

  return (
    <div className="flex flex-col items-center w-full mt-8 mb-16 px-4 md:px-16 mx-auto max-w-screen-2xl">
      <h2 className="text-3xl font-bold text-center mb-6">
        Lost & Found <span className="text-yellow-500">Corner</span>
      </h2>
      <div className="flex flex-col justify-center items-center w-full">
        <div data-aos="zoom-in-right" className="w-full">
          <div className="flex justify-center">
            <div className="overflow-x-auto w-full">
              <div className="flex space-x-4 px-2">
                {missingPets.map((pet) => (
                  <div
                    key={pet.id}
                    onClick={() => openModal(pet)}
                    className="flex-shrink-0 bg-white rounded-lg shadow-lg m-2 p-4 space-y-4 w-64 cursor-pointer hover:shadow-xl transition-shadow duration-200"
                  >
                    <div className="w-full aspect-square overflow-hidden rounded-xl">
                      <img
                        src={pet.petImage}
                        alt={`${pet.name} Image`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <h3 className="text-lg font-semibold text-center">{pet.name}</h3>
                    <p className="text-gray-500 text-center">
                      {pet.petAge} Year - {pet.petGender}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedPet && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black bg-opacity-50" onClick={closeModal}></div>
          <div className="relative bg-white rounded-xl shadow-2xl p-6 md:p-8 w-11/12 max-w-4xl mx-auto z-10">
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="flex flex-col md:flex-row items-start md:space-x-6">
              <div className="flex-shrink-0 w-full md:w-1/2 flex justify-center mb-2 md:mb-0">
                <div className="w-64 h-64 bg-gray-100 rounded-xl overflow-hidden">
                  <img
                    src={selectedPet.petImage}
                    alt={selectedPet.name}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
              <div className="flex-grow">
                <h3 className="text-2xl font-bold mb-2">
                  <span className="text-yellow-500">{selectedPet.name}</span>
                </h3>
                <p className="text-gray-600 mb-4">
                  Age: {selectedPet.petAge} Year(s) | Gender: {selectedPet.petGender}
                </p>
                <div className="space-y-2">
                  <h4 className="text-xl font-semibold text-gray-800">Lost Details</h4>
                  <p className="text-gray-700">
                    Location: {petAddress || 'Loading address...'}
                  </p>
                  <p className="text-gray-700">
                    Date:{' '}
                    {selectedPet.petCurrentStatus?.missingDateandTime &&
                    typeof selectedPet.petCurrentStatus.missingDateandTime.toDate === 'function'
                      ? selectedPet.petCurrentStatus.missingDateandTime.toDate().toLocaleString()
                      : selectedPet.petCurrentStatus?.missingDateandTime || 'N/A'}
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-gray-700 font-semibold">
                    If you have any information, please email{' '}
                    <a href="mailto:Info@intellipaw.io" className="text-blue-500 underline">
                      Info@intellipaw.io
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MissingPets;
