import React from 'react';
import workflowImage from '../assets/dogimg/mobile101.png';
// src/components/FeatureSections.js


const WorkFlow = () => {
  return (
    <section className="py-16">
      <div className="max-w-screen-xl mx-auto px-6 sm:px-12 lg:px-24">
        {/* Section Heading */}
        <h2 className="text-3xl lg:text-4xl font-semibold text-center text-darken mb-12">
          <span className="text-yellow-500">Intellipaw </span> workflow from <span className="text-yellow-500">scan to reunion</span>
        </h2>

        <div className="flex flex-col lg:flex-row items-start">
          {/* Left Side - Image */}
          <div className="w-full lg:w-2/3 mb-10 lg:mb-0 flex justify-center">
            <img
              src={workflowImage}
              alt="Eatnice Workflow"
              className="w-full h-auto"
            />
          </div>

          {/* Right Side - Vertical Timeline */}
          <div className="w-full lg:w-1/2 relative">
            <div className="border-l-4 ml-4" style={{ borderColor: "#0095FF" }}>
              {/* Timeline Item 1 */}
              <div className="mb-10 relative">
                <div className="absolute -left-5 top-0 w-10 h-10 bg-custom-blue rounded-full flex items-center justify-center text-white font-bold">
                  1
                </div>
                <h3 className="ml-8 text-xl font-semibold text-darken mb-2">
                  Scan Pet
                </h3>
                <p className="ml-8 text-gray-700">
                  Capture your pet's unique features using your smartphone's camera; our AI will create a precise biometric profile.
                </p>
              </div>

              {/* Timeline Item 2 */}
              <div className="mb-10 relative">
                <div className="absolute -left-5 top-0 w-10 h-10 bg-custom-blue rounded-full flex items-center justify-center text-white font-bold">
                  2
                </div>
                <h3 className="ml-8 text-xl font-semibold text-darken mb-2">
                  Pet Profile
                </h3>
                <p className="ml-8 text-gray-700">
                  Enter your pet's name, breed, sex, and birth date to complete their profile.


                </p>
              </div>

              {/* Timeline Item 3 */}
              <div className="mb-10 relative">
                <div className="absolute -left-5 top-0 w-10 h-10 bg-custom-blue rounded-full flex items-center justify-center text-white font-bold">
                  3
                </div>
                <h3 className="ml-8 text-xl font-semibold text-darken mb-2">
                  Your Info
                </h3>
                <p className="ml-8 text-gray-700">
                  Provide your contact details to link with your pet's profile, ensuring prompt communication if your pet is found.

                </p>
              </div>

              {/* Timeline Item 4 */}
              <div className="mb-10 relative">
                <div className="absolute -left-5 top-0 w-10 h-10 bg-custom-blue rounded-full flex items-center justify-center text-white font-bold">
                  4
                </div>
                <h3 className="ml-8 text-xl font-semibold text-darken mb-2">
                  Rescue Alert                </h3>
                <p className="ml-8 text-gray-700">
                  In case of a lost pet, send alerts to nearby users to facilitate a swift reunion.

                </p>
              </div>

              {/* Timeline Item 5 */}
              <div className="mb-10 relative">
                <div className="absolute -left-5 top-0 w-10 h-10 bg-custom-blue rounded-full flex items-center justify-center text-white font-bold">
                  5
                </div>
                <h3 className="ml-8 text-xl font-semibold text-darken mb-2">
                  Find Vet
                </h3>
                <p className="ml-8 text-gray-700">
                  Locate nearby veterinarians for your pet's healthcare needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkFlow;

