import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import intellipawlogo from '../assets/companylogo/intellipawlogo.png';

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <nav className="w-full bg-cream text-gray-700">
      <div className="max-w-screen-xl mx-auto px-8 flex items-center justify-between py-4">
        {/* Left Section - Logo */}
        <div className="flex items-center">
          <Link to="/">
            <img
              src={intellipawlogo}
              alt="Intellipaw Logo"
              className="w-16 h-16 rounded-full object-cover border-2 border-white"
            />
          </Link>
        </div>

        {/* Center Section - Navigation Links (Desktop) */}
        <div className="hidden md:flex space-x-6">
          <Link
            to="/"
            className="px-3 py-2 text-md font-medium hover:text-gray-900 focus:outline-none focus:shadow-outline"
          >
            Home
          </Link>
          <Link
            to="/blog"
            className="px-3 py-2 text-md font-medium hover:text-gray-900 focus:outline-none focus:shadow-outline"
          >
            Blog
          </Link>
          <Link
            to="/missing-pet"
            className="px-3 py-2 text-md font-medium hover:text-gray-900 focus:outline-none focus:shadow-outline"
            onClick={() => setOpen(false)}
          >
            Missing Pet
          </Link>
          <Link
            to="/Data-for-justice"
            className="px-3 py-2 text-md font-medium hover:text-gray-900 focus:outline-none focus:shadow-outline"
            onClick={() => setOpen(false)}
          >
            Data for Justice
          </Link>
          <Link
            to="/about-us"
            className="px-3 py-2 text-md font-medium hover:text-gray-900 focus:outline-none focus:shadow-outline"
            onClick={() => setOpen(false)}
          >
            About Us
          </Link>
          <Link
            to="/contact-us"
            className="px-3 py-2 text-md font-medium hover:text-gray-900 focus:outline-none focus:shadow-outline"
          >
            Contact Us
          </Link>
        </div>

        {/* Right Section - Desktop Buttons */}
        <div className="hidden md:flex items-center space-x-4">
          {/* Download Button */}
          <Link
            to="/download"
            className="px-6 py-2 text-lg font-medium text-white bg-yellow-500 rounded-full focus:outline-none focus:shadow-outline"
          >
            Download
          </Link>
          {/* Register Button (Desktop) */}
          <Link
            to="/register"
            className="px-6 py-2 text-lg font-medium text-white bg-indigo-500 rounded-full focus:outline-none focus:shadow-outline hover:bg-indigo-600"
          >
            Register
          </Link>
        </div>

        {/* Mobile menu button (Hamburger Icon) */}
        <div className="md:hidden">
          <button
            onClick={() => setOpen(!open)}
            className="text-gray-800 focus:outline-none focus:shadow-outline"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              {open ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {open && (
        <div className="md:hidden bg-cream">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link
              to="/"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
              onClick={() => setOpen(false)}
            >
              Home
            </Link>
            <Link
              to="/blog"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
              onClick={() => setOpen(false)}
            >
              Blog
            </Link>
            <Link
              to="/missing-pet"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
              onClick={() => setOpen(false)}
            >
              Missing Pet
            </Link>
            <Link
              to="/Data-for-justice"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
              onClick={() => setOpen(false)}
            >
              Data for Justice
            </Link>
            <Link
              to="/about-us"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
              onClick={() => setOpen(false)}
            >
              About Us
            </Link>
            <Link
              to="/contact-us"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
              onClick={() => setOpen(false)}
            >
              Contact Us
            </Link>

            {/* Mobile Buttons */}
            <div className="mt-4 flex flex-col space-y-2">
              <Link
                to="/download"
                className="block text-center px-3 py-2 rounded-full text-base font-medium text-white bg-yellow-500 focus:outline-none focus:shadow-outline"
                onClick={() => setOpen(false)}
              >
                Download
              </Link>
              {/* Register Button (Mobile) */}
              <Link
                to="/register"
                className="block text-center px-3 py-2 rounded-full text-base font-medium text-white bg-indigo-500 focus:outline-none focus:shadow-outline hover:bg-indigo-600"
                onClick={() => setOpen(false)}
              >
                Register
              </Link>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
